// resumeDataSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  //basic profile
  name: '',
  introduction: '',
  first_name: '',
  last_name: '',

  //contact information
  email: '',
  location: '',
  phone: '',
  portfolio_links: [],

  //job preferences
  languages: [],

  //work experience
  workExperience: [],

  //education details
  educationDetails: [],

  //additional information
  additionalInfo: {},

};

const resumeDataSlice = createSlice({
  name: 'resumeData',
  initialState,
  reducers: {
    setResumeData: (state, action) => {
      state.name = action.payload.name;
      state.introduction = action.payload.introduction;
      state.email = action.payload.email;
      state.location = action.payload.location;
      state.phone = action.payload.phone;
      state.portfolio_links = action.payload.portfolio_links;
      state.languages = action.payload.languages;
      state.workExperience = action.payload.workExperience;
      state.educationDetails = action.payload.educationDetails;
      state.additionalInfo = action.payload.additionalInfo;
    },
    setName : (state, action) => {
      state.name = action.payload;
    },
    setFirstName:(state, action)=>{
      state.first_name=action.payload;
    },
    setLastName:(state, action)=>{
      state.last_name=action.payload;
    },
    setIntroduction : (state, action) => {
      state.introduction = action.payload;
    },
    setLocation : (state, action) => {
      state.location = action.payload;
    },
    setPhone : (state, action) => {
      state.phone = action.payload;
    },
    setPortfolioLinks : (state, action) => {
      state.portfolio_links = action.payload;
    },
    setWorkExperience : (state, action) => {
      state.workExperience = action.payload;
    },
    setEducationDetails : (state, action) => {
      state.educationDetails = action.payload;
    },
    setAdditionalInfo : (state, action) => {
      state.additionalInfo = action.payload;
    },
    clearData: (state) => {
      state.name = '';
      state.introduction = '';
      state.email = '';
      state.location = '';
      state.phone = '';
      state.portfolio_links = [];
      state.languages = [];
      state.workExperience = [];
      state.educationDetails = [];
      state.additionalInfo = [];
      state.first_name='';
      state.last_name='';
    },
  },
});

export const { setResumeData, setName, setIntroduction, setLocation, setPhone, setPortfolioLinks, setWorkExperience, setEducationDetails, setAdditionalInfo, clearData,setFirstName, setLastName } = resumeDataSlice.actions;
export const selectResumeData = (state) => state.resumeData.resumeData;
export default resumeDataSlice.reducer;
