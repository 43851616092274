import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  results: null,
  Cv_url: "",
  cv_name: "",
  cv_type: "",
  cv_id: "",
  parse_data: null, // Stores the parsed data (e.g., name, email, etc.)
  jd_data: null,
  is_trial: false,
  rewritten_cv: null,
  is_rewritten_cv_applied: false,
  resumeData: null,
  cvFile: null,
};

const CvAnalysisDataSlice = createSlice({
  name: "cvAnalysisData",
  initialState,
  reducers: {
    setResults: (state, action) => {
      console.log(action.payload.results);
      state.results = action.payload.results;
      state.Cv_url = action.payload.Cv_url;
      state.cv_name = action.payload.cv_name;
      state.cv_type = action.payload.cv_type;
      state.cv_id = action.payload.cv_id;
      state.jd_data = action.payload.jd_data;
      state.parse_data = action.payload.parse_data;
      state.is_trial = action.payload.is_trial;
      state.rewritten_cv = action.payload.rewritten_cv;
      state.is_rewritten_cv_applied = action.payload.is_rewritten_cv_applied;
      state.resumeData = action.payload.resumeData;
      state.cvFile = action.payload.cvFile;
    },

    setRewrittenCv: (state, action) => {
      state.rewritten_cv = action.payload.rewritten_cv;
    },

    setIsRewrittenCvApplied: (state, action) => {
      state.is_rewritten_cv_applied = action.payload.is_rewritten_cv_applied;
    },

    setResumeData: (state, action) => {
      state.resumeData = action.payload.resumeData;
    },

    clearResults: (state) => {
      state.results = null;
      state.Cv_url = "";
      state.cv_name = "";
      state.cv_type = "";
      state.cv_id = "";
      state.parse_data = null;
      state.jd_data = null;
      state.is_trial = false;
      state.rewritten_cv = null;
      state.is_rewritten_cv_applied = false;
      state.resumeData = null;
    },

    setParseData: (state, action) => {
      state.parse_data = action.payload.parse_data;
    },

    updateParseDataField: (state, action) => {
      const { field, value } = action.payload;
      if (state.resumeData) {
        state.resumeData[field] = value;
      } else {
        state.resumeData = { [field]: value };
      }
    },
  },
});

export const {
  setResults,
  clearResults,
  updateParseDataField,
  setParseData,
  setRewrittenCv,
  setIsRewrittenCvApplied,
  setResumeData,
} = CvAnalysisDataSlice.actions;

export default CvAnalysisDataSlice.reducer;
