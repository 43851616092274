import axios from "axios";
import api, { API_ML_BASE_URL } from "../services";
import api_ml from "../services";

export const getEmployerCompany = async (query) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/companies-employer?q=${query}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    return [];
  }
};

export const getCompanyData = async (name, linkedin_url) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/company-autofill?linkedin-url=${linkedin_url}&company-name=${name}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

export const getCompanyByName = async (name) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/company-autofill?company-name=${name}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

export const getCountries = async (name) => {
  try {
    const response = await api.get(`/autofill/external/v1/countries?q=${name}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error;
  }
};

export const createProfile = async (userProfile, token) => {
  try {
    const response = await api.post(
      "/employer/external/v1/company",
      userProfile,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const getEmployerProfile = async (token) => {
  try {
    const res = await api.get("/employer/external/v1/profile", {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res;
  } catch (error) {
    console.error("Error fetching basic profile data:", error);
    throw error;
  }
};

export const headhuntingEnqiry = async (token, data) => {
  try {
    const res = await api.post("/employer/external/v1/headhunting/enquiry", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res?.data;
  } catch (error) {
    console.error("Error fetching basic profile data:", error);
    throw error;
  }
};


export const remainingCredit = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/job-post/remaining-creds",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export const getCvMaskingCredits = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/cv-masking/credits/total",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getCvMaskingProducts = async (token, country_code) => {
  try {
    const res = await api.get(
      `/employer/external/v1/cv-masking/products?country_code=${country_code}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getpool = async (token) => {
  try {
    const res = await api.get(`employer/external/v1/pool/purchase-history`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

//PATCH Basic profile
export const patchBasicProfile = (userInfoUpdated, token) => {
  return api.put(`/employer/external/v1/profile`, userInfoUpdated, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

/**
 * Deletes an account by making a DELETE request to a specific API endpoint.
 * @param {string} token - The authentication token used to authorize the request.
 * @param {object} payload - The payload data to be sent in the request body.
 * @returns {Promise} - A promise that resolves with the response data from the API if the request is successful.
 * @throws {Error} - If an error occurs during the request.
 */
export const deleteAccountpermennatly = async (token, payload) => {
  try {
    const response = await api.delete("/employer/external/v1/profile", {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deactivateAccountRequest = async (token) => {
  try {
    const response = await api.get("/employer/external/v1/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// POST JOB APIS

export const getJobTitlesOfIndustry = async (query, industry, token) => {
  // /autofill/external/v1/industry/job-titles?q=mechani&industry=energy
  try {
    const response = await api.get(
      `/autofill/external/v1/industry/job-titles?q=${query}&industry=${industry}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job titles:", error);
    return [];
  }
};

export const getTitles = async (query, token) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/job-titles?title=${query}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job titles:", error);
    return [];
  }
};


export const generateRolesResponsibilities = async (title, sector) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/roles-responsiblities?title=${title}&sector=${sector}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching roles and responsibilities:", error);
    return [];
  }
};

export const generateRolesByTitle = async (title, sector) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/roles-responsiblities?title=${title}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching roles and responsibilities:", error);
    return [];
  }
};

// AUTOFILL APIS
export const getSkillSuggestionEmployer = async (query, token) => {
  try {
    const response = await api.get(
      `/autofill/external/v1/skills?name=${query}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching skills:", error);
    return [];
  }
};

export const getCompaniesEmployer = (token) => {
  // Define the API endpoint with the "q" query parameter
  const endpoint = `/employer/external/v1/company`;

  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

export const createNewProfile = async (userProfile, token) => {
  try {
    const response = await api.post(
      "/employer/external/v1/recruiter/company",
      userProfile,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const updateRecriterCompany = async (company_id, values, token) => {
  try {
    const response = await api.patch(
      `/employer/external/v1/recruiter/company/${company_id}`,
      values,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const getrecruiterById = async (token, jobId) => {
  try {
    const response = await api.get(
      `/employer/external/v1/recruiter/company/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recruiter company by ID:", error);
    throw error;
  }
};

export const employerCompany = async (token) => {
  try {
    const response = await api.get("employer/external/v1/company", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching employer Company:", error);
    throw error;
  }
};

export const getrecruiterCompany = async (token) => {
  try {
    const response = await api.get("employer/external/v1/recruiter/companies", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error fetching employer Company:", error);
    throw error;
  }
};

export const patchemployerCompany = (company_id, userData, token) => {
  return api.patch(`/employer/external/v1/company/${company_id}`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteRecruiterComapnybyId = (token, jobId) => {
  try {
    const response = api.delete(
      `/employer/external/v1/recruiter/company/${jobId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recruiter company by ID:", error);
    throw error;
  }
};

export const getAllCompany = async (token) => {
  try {
    const response = await api.get(`employer/external/v1/recruiter/companies`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Comapny:", error);
    throw error;
  }
};

export const getAddress = async (token) => {
  try {
    const response = await api.get(`employer/external/v1/address`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting  Address:", error);
    throw error;
  }
};

export const postJob = async (headers, postJobData) => {
  try {
    const response = await api.post(
      `/employer/external/v1/job-post`,
      postJobData,
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const postTrialJob = async (headers, postJobData) => {
  try {
    const response = await api.post(
      `/employer/external/v1/job-post/trial`,
      postJobData,
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// export const VerificationRequest = async (headers, payload) => {
//   try {
//     const response = await api.post(`/applicant/external/v1/scaling/scaling-questions`,payload,
//       { headers }
//     );
//     return response;
//   } catch (error) {
//     throw error;
//   }
// };

export const updatePostJob = async (headers, postJobData, jobPost_id) => {
  try {
    const response = await api.put(
      `/employer/external/v1/job-post/${jobPost_id}`,
      postJobData,
      { headers }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCurrencyList = async () => {
  try {
    const response = await api.get(`/autofill/external/v1/currencies`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const AddBillingAddress = async (userDetails, token) => {
  try {
    const response = await api.post(
      "employer/external/v1/address",
      userDetails,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateBillingAddress = async (userDetails, token) => {
  try {
    const response = await api.patch(
      "employer/external/v1/address",
      userDetails,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCountry = async (query) => {
  try {
    const response = await api.get(`autofill/external/v1/countries?q=${query}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getState = async (state_id) => {
  try {
    const response = await api.get(`autofill/external/v1/states/${state_id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAvuaPoolCandidatesCount = async (queryFilters, token) => {
  try {
    const response = await api.post(
      `/employer/external/v1/pool/count`,
      queryFilters,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAvuaPoolCandidates = async (
  queryFilters,
  token,
  isPoolSerch
) => {
  console.log("THis is the query filter", queryFilters);
  try {
    const response = await api.post(
      isPoolSerch
        ? `/employer/external/v1/project/pool-search`
        : `/employer/external/v1/pool/search`,
      queryFilters,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postProject = async (token, data) => {
  try {
    const response = await api.post(`/employer/external/v1/project`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProjects = async (token, offset = 0, limit = 4) => {
  try {
    const response = await api.get(`/employer/external/v1/projects?offset=${offset}&limit=${limit}`,
  
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const pipelineCandidates = async (token, project_id, limit, offset) => {
  try {
    const response = await api.get(
      `/employer/external/v1/project/all-candidate-status?project_id=${project_id}&offset=${offset}&limit=${limit}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editProject = async (token, data) => {
  try {
    const response = await api.patch(`/employer/external/v1/project`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const saveCandidates = async (token, data) => {
  try {
    const response = await api.post(
      `/employer/external/v1/project/candidate-create`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCandidates = async (token, data) => {
  try {
    const response = await api.patch(
      `/employer/external/v1/project/candidate-remark`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCandidates = async (
  token,
  project_id,
  candidate_status,
  offset,
  limit
) => {
  try {
    const response = await api.get(
      `/employer/external/v1/project/candidate-info/${project_id}?candidate_status=${candidate_status}&offset=${offset}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRemark = async (token, project_id) => {
  try {
    const response = await api.get(
      `/employer/external/v1/project/candidate-status?project_id=${project_id}&offset=0&limit=15`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCandidatesNotes = async (token, data) => {
  try {
    const response = await api.post(
      `/employer/external/v1/project/candidate-update`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalPoolCredits = async (token) => {
  try {
    const response = await api.get(`/employer/external/v1/pool/total-credits`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLeastCreditDetails = async (token) => {
  try {
    const response = await api.get(`/employer/external/v1/pool/least-credits`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

 export const getTotalCreditDetails = async (token) => {
  try {
    const response = await api.get(`/employer/external/v1/pool/total-credits-by-currency`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
 }

export const getEmployerVisitedDates = async (token, limit, offset) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/applicant/visited-dates?limit=${limit}&offset=${offset}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getViewedCandidates = async (token, date, limit, offset) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/viewed-candidates?visited_on=${date}&limit=${limit}&offset=${offset}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPoolSearchHistory = async (token) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/search-history`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPoolProjctSearchHistory = async (token, project_id) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/search-history?project_Id=${project_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSaveHistory = async (token, data) => {
  try {
    const response = await api.post(
      `/employer/external/v1/project/save-history`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const postCvMaskingRequest = async (queryFilters, token) => {
  try {
    const response = await api.post(
      "/employer/external/v1/cv-masking/mask",
      queryFilters,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCvSimilarRequest = async (
  queryParamsPath,
  token,
  country = "None"
) => {
  try {
    console.log("Calling the CV parser API for similar CV");
    // Define the base params object without the country_code
    const params = {
      "cv-path": queryParamsPath,
    };
    // Add country_code only if it's not 'None' or an empty string
    if (country && country !== "None") {
      params["country_code"] = country;
    }
    const response = await api.get(
      "/employer/external/v1/similar_cv/cv-parser",
      {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
      }
    );
    console.log(
      "This is the response I got for CV-parser similar CV",
      response
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCvSimilarCredits = async (token) => {
  try {
    const res = await api.get(
      "/employer/external/v1/similar_cv/credits/total",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getCvSimilarProducts = async (token, countryCode = "IN") => {
  try {
    if (!countryCode) return;
    const adjustedCountryCode = countryCode == "IN" ? "IN" : "US";
    const res = await api.get("/payments/external/v1/products", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        country_code: adjustedCountryCode,
        product_type: "similar_cv",
      },
    });
    console.log("This is the product data and pricing ", res?.data);

    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const postInitiateInterview = async ( data, token) => {
  try {
    const response = await api.post("/ai-interview/external/v1/initiate-inteview", 
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteInterview = async (token, id) => {
  try {
    const response = await api.delete(
      `/ai-interview/external/v1/interview/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const  getInterviewListData = async (token, limit=500,offset=0,) => {
  try {
    const response = await api.get(
      `/ai-interview/external/v1/interviews?offset=${offset}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInterviewCandidates = async (token, id, offset=0, limit=100) => {
  try {
    const response = await api.get(
      `/ai-interview/external/v1/interview/${id}/candidates?offset=${offset}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInterviewReportDetails = async (token, id) => {
  try {
    const response = await api.get(
      `/ai-interview/external/v1/interview/candidate-report/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFirstQuestion = async (token, room_id) => {
  try {
    const response = await api.get(
      `/ai-interview/external/v1/start-interview?room_id=${room_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addCandidateToInterview = async ( payload, token) => {
  try {
    const response = await api.post(
      `/ai-interview/external/v1/interview/add-candidate`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const evaluateQuestion = async ( payload, token) => {
  try {
    const response = await api.post(
      `/ai-interview/external/v1/evaluate-question`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCandidateDetails = async (token, room_id) => {
  try {
    const response = await api.get(
     `/ai-interview/external/v1/interview/get-candidate/${room_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSimilarCvHistoryList = async (
  token,
  limit,
  offset
) => {
  try {
    const response = await api.get("/employer/external/v1/similar_cv/cv-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        offset: offset,
      },
    });
    console.log("this is CV Similar History", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
export const getViewedCandidatesIdealMatch = async (
  token,
  limit,
  offset,
  similarCvId
) => {
  try {
    const response = await api.get(
      `/employer/external/v1/pool/viewed-candidates?limit=${limit}&offset=${offset}&employer_ref_cvs_id=${similarCvId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const inviteMembers = async (invite, token) => {
  try {
    const response = await api.post(
      "/employer/external/v1/teams/members/invite",
      invite,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("this is the response  from the invite Members API");
    return response?.data;
  } catch (error) {
    console.log("thiss is noot", error);
    return error?.response;
  }
};
export const getAdminCredits = async (token, teams_id) => {
  try {
    const response = await api.get(
      `/employer/external/v1/teams/members/admin/
${teams_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getMemberList = async (token, limit=10, offset=0) => {
  try {
    const response = await api.get("/employer/external/v1/teams/members", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        offset: offset,
      },
    });
    console.log("this is member list ", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMember = async (token, teams_id, member_id) => {
  try {
    console.log("this is the params I am sending ", member_id);
    const response = await api.delete(
      `/employer/external/v1/teams/member/${teams_id}?member_id=${member_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the delete response  ", response);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getApprovalList = async (token, limit, offset, teams_id) => {
  try {
    const response = await api.get(
      `/employer/external/v1/teams/request/admin/${teams_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit: limit,
          offset: offset,
          is_approved: false,
        },
      }
    );
    console.log("this is Approval list ", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getMember = async (token, teams_id, member_id) => {
  try {
    console.log("this is the params I am sending ", member_id);
    const response = await api.get(
      `/employer/external/v1/teams/member/${teams_id}?member_id=${member_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the get member credit response   ", response);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const updateMemberCredits = async (token, teams_id, body) => {
  try {
    console.log(
      "this is the params I am sending for patch request for update  ",
      body
    );
    const response = await api.patch(
      `/employer/external/v1/teams/members/admin/${teams_id}`,
      body,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the get member credit response   ", response);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const isAdminDetails = async (token, teams_id) => {
  try {
    const res = await api.get(
      `/employer/external/v1/teams/members/${teams_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(
      "This is the response when we were checking its admin or not ",
      res
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updateMemberStatus = async (token, status) => {
  try {
    const response = await api.patch(
      `/employer/external/v1/teams/member`,
      status,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("THis is the response from status update ", response);
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const getRequestList = async (token, teams_id) => {
  try {
    console.log("this is the teams ID I am sending ", teams_id);
    const response = await api.get(
      `/employer/external/v1/teams/request/${teams_id}?is_approved=false`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the request list i got ", response);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const requestFeature = async (token, request) => {
  try {
    const response = await api.post(
      "/employer/external/v1/teams/request",
      request,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("this is the response  request feature API");
    return response?.data;
  } catch (error) {
    console.log("thiss is error ", error);
    return error?.response;
  }
};

export const patchRequestUpdate = async (token, body, member_id) => {
  try {
    const response = await api.patch(
      `/employer/external/v1/teams/request/admin/${member_id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is approval endpoint  ", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const resendInvitation = async (token, teams_id, member_id) => {
  try {
    console.log("this is the teams ID I am sending ", teams_id);
    const response = await api.get(
      `/employer/external/v1/teams/members/resend-invitation/${teams_id}?member_id=${member_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(
      "this is the response for response for resend invitation ",
      response
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const inviteMemberProjects = async (token, request) => {
  try {
    console.log(
      "THis is my body i am trying to send to the invite member API",
      request
    );
    const response = await api.post(
      "/employer/external/v1/project/invite-members",
      request,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("this is the response  from project invitation invite ");
    return response?.data;
  } catch (error) {
    console.log("thiss is error ", error);
    return error?.response;
  }
};

export const getProjectNamesFilter = async (token, body) => {
  try {
    const response = await api.post(
      `/employer/external/v1/project/filter`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(
      "This is the response from post project details filter API",
      response
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchMemberList = async (
  token,
  limit = 4,
  offset = 0,
  search_name = "",
  teams_id,
  project_id = null
) => {
  try {
    const response = await api.get("/employer/external/v1/project/members", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        offset: offset,
        search_term: search_name || "",
        teams_id: teams_id,
        ...(project_id && { project_id: project_id }),
      },
    });
    console.log("this is member list ", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getProjectDetails = async (token, project_id) => {
  try {
    console.log("this is the member ID I am sending ", project_id);
    const response = await api.get(
      `/employer/external/v1/project/${project_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(
      "this is the response for response for get project details  ",
      response
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getLogsProject = async (
  token,
  limit = 4,
  offset = 0,
  teams_id = null,
  project_id = null,
  start_date = "",
  end_date = ""
) => {
  try {
    const response = await api.get("/employer/external/v1/project/logs", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        offset: offset,
        project_id: project_id,
        ...(teams_id && { teams_id: teams_id }),
        start_date: start_date,
        end_date: end_date,
      },
    });
    console.log("this is log list ", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};


export const getAvailableLicense = async (token, teams_id) => {
  try {
    console.log("this is the teams ID I am sending ", teams_id);
    const response = await api.get(
      `/employer/external/v1/teams/license/${teams_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the request license", response);
    return response?.data;
  } catch (error) {
    throw error;
  }
};


export const deleteMemberProject = async (token, project_id, member_id) => {
  try {
    const response = await api.delete(
      `/employer/external/v1/project/members/${project_id}?member_id=${member_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the delete response for projects  ", response);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdminDetails = async (token)=>{
  try {
    const response = await api.get(
      `/employer/external/v1/teams/member/admin`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the request for the admin details", response);
    return response?.data;
  } catch (error) {
    throw error;
  } 
}



export const deleteMemberTeams = async (token, teams_id, member_id) => {
  try {
    const response = await api.delete(
      `/employer/external/v1/teams/member/remove/${teams_id}?member_id=${member_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("this is the delete member response for teams  ", response);
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchGhostUserList = async (
  token,
  limit = 4,
  offset = 0,
  search_term = null,
  teams_id,
) => {
  try {
    const response = await api.get(`/employer/external/v1/teams/members/ghost-user/${teams_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit: limit,
        offset: offset,
        ...(search_term ? { search_term } : {})
      },
    });
    console.log("this is ghost user list  ", response?.data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const transferOwnershipApi = async (token, teams_id, body) => {
  try {
    const response = await api.put(
      `/employer/external/v1/teams/members/transfer/${teams_id}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log('this is the response for transfer ownership', response);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getTeamsLicense = async (token, countryCode = "IN") => {
  try {
    if (!countryCode) return;
    const adjustedCountryCode = countryCode == "IN" ? "IN" : "US";
    const res = await api.get("/payments/external/v1/products", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        country_code: adjustedCountryCode,
        product_type: "teams",
      },
    });
    console.log("This is the product data and pricing for teams ", res?.data);

    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getCandidateRemark = async (token, project_id,employer_id) => {
  try {
    const response = await api.get(
    `/employer/external/v1/project/${project_id}/candidate/${employer_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log('efjcbejhbvef this is the response from get candidate remark', response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getJobPosting = async (jobId, token = null) => {
  try {
    const config = {
      params: {},
      headers: {},
    };

    if (token) {
      config.params['jwt-token'] = token;
    }

    const response = await api.get(
      `/public/external/v1/job-post/${jobId}`,
      config
    );

    console.log('This is the response from jobposting API', response);
    return response.data;
  } catch (error) {
    console.error("Error fetching job titles:", error);
    throw error;
  }
};
export const resendInterviewInvitation = async (payload, token) => {
  try {
    const response = await api.post(
      "/ai-interview/external/v1/resend-invitation",
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const deleteProject = async (token, project_id) => {
  try {
    const response = await api.delete(
      `/employer/external/v1/project/${project_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const  getMockInterviewListData = async (token, limit=500,offset=0,) => {
  try {
    const response = await api.get(
      `/mock-interview/external/v1/interviews?offset=${offset}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createMockInterview = async (payload, token) => {
  try {
    const response = await api.post(
      "/mock-interview/external/v1/create-interview",
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log('api  level response create mock interview' , response?.data);
    return response?.data;
  } catch (error) {
    console.log("Error fetching create Profile:", error);
    throw error;
  }
};

export const evaluateQuestionsMock = async ( payload, token) => {
  try {
    const response = await api.post(
      `/mock-interview/external/v1/evaluation`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getMockInterviewReport = async (token, id) => {
  try {
    const response = await api.get(
      `/mock-interview/external/v1/report/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getMockInterviewCredits = async (token,product_type) => {
  try {
    const response = await api.get(
      `/payments/external/v1/applicant/remaining-creds?product_type=${product_type}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getMockInterviewProduct = async (token, country_code, product_type) => {
  try {
    const res = await api.get(
      `/payments/external/v1/applicant/products?country_code=${country_code}&product_type=${product_type}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};






export const getAiInterviewCredits = async (token) => {
  try {
    const res = await api.get(
      "/ai-interview/external/v1/interview/credits?is_simple=true",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getSmartHireProduct = async (token, countryCode = "IN") => {
  try {
    if (!countryCode) return;
    const adjustedCountryCode = countryCode == "IN" ? "IN" : "US";
    const res = await api.get("ai-interview/external/v1/interview/products", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        country_code: adjustedCountryCode
      },
    });
    console.log("This is the product data and pricing ", res?.data);

    return res?.data;
  } catch (error) {
    throw error;
  }
};
export const getCandidatesJd = async ( file, token) => {
  try {
    const response = await api.post(
      `/employer/external/v1/jd-matching`,
      file,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getRefreshCandidate = async (token, jd_id) => {
  try {
    const res = await api.get(
      `/employer/external/v1/jd-matching/referesh-candidates/${jd_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res?.data;
  } catch (error) {
    throw error;
  }
};






































